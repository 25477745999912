import { CompareInfos, REMOVE_ALL_COMPARE, SAVE_SEARCH_PAGE_PATH, UPDATE_COMPARE_BY_SLUG } from '../types/compare'

const initialState: CompareInfos = {
  solutions: [],
  path: '',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_COMPARE_BY_SLUG: {
      const vendor = action.payload.vendor
      const remove = action?.payload?.remove
      let solutions = state.solutions

      if (remove) {
        solutions = solutions.filter((item) => item.slug !== vendor.slug)
      } else {
        const isFound = solutions.find((item) => item.slug === vendor.slug)
        if (isFound) {
          solutions = solutions.filter((item) => item.slug !== vendor.slug)
        } else {
          solutions.push(vendor)
        }
      }
      return {
        ...state,
        solutions,
      }
    }
    case REMOVE_ALL_COMPARE: {
      return {
        ...state,
        solutions: [],
      }
    }
    case SAVE_SEARCH_PAGE_PATH: {
      const path = action.payload.path
      return {
        ...state,
        path: path,
      }
    }
    default:
      return state
  }
}

export default reducer
