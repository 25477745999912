const useClearCookiesExceptConsent = () => {
  const isConsentAccepted = localStorage.getItem('isConsentAccepted')
  if (isConsentAccepted) {
    localStorage.clear()
    localStorage.setItem('isConsentAccepted', isConsentAccepted)
  } else {
    localStorage.clear()
  }
}

export default useClearCookiesExceptConsent
